<template>
  <div>
    <v-menu offset-y class="mt-4" nudge-bottom="16" :close-on-content-click="false" max-height="560">
      <template v-slot:activator="{ attr, on }">
        <v-btn icon class="mr-1" v-bind="attr" v-on="on">
          <v-badge offset-y="10" offset-x="10" color="red" :value="hasNewNotifications" :content="hasNewNotifications">
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>


      <v-list max-width="700" min-width="700">

        <v-list-group
            v-for="(category, j) in notificationCategories"
            :key="j"
            v-model="category.active"
            class="max-height overflow-y-auto"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ $t("notifications." + category.slug + ".title") }}
                <v-badge class="ml-4" color="error" :value="category.items.filter(a => !a.seen).length"
                         :content="category.items.filter(a=>!a.seen).length"></v-badge>
              </v-list-item-title>
            </v-list-item-content>
          </template>

<!--            <v-virtual-scroll-->
<!--                bench="2"-->
<!--                :items="category.items"-->
<!--                :item-height="category.height"-->
<!--                height="256"-->
<!--            >-->
<!--              <template v-slot:default="{ item }">-->
<!--                <component-->
<!--                   :is="category.component"-->
<!--                   :notification="item"-->
<!--                   :category="category">-->
<!--                </component>-->
<!--              </template>-->
<!--            </v-virtual-scroll>-->

          <vue-scroll class="mr-4">
            <div :style="'height: '+calcHeight(category)+'px;'">
              <template v-for="(item, i) in category.items">
                <component
                   :key="'notif'+category+i"
                   :is="category.component"
                   :notification="item"
                   :category="category">
                </component>
              </template>
            </div>
          </vue-scroll>


          <v-btn fab fixed x-small right bottom color="primary" v-show="category.active"
                 @click="category.active = false">
            <v-icon>mdi-chevron-double-up</v-icon>
          </v-btn>
        </v-list-group>

      </v-list>
    </v-menu>
  </div>
</template>

<script>
import NotificationsService from "@/Services/NotificationsService";
import Vuex from "vuex";

export default {
  name: "NotificationCenter",
  components: {
    PoseSdp: () => import("@/Components/Commons/Interface/Notifications/PoseSdp"),
    Interim: () => import("@/Components/Commons/Interface/Notifications/Interim"),
    Commandes: () => import("@/Components/Commons/Interface/Notifications/Commandes"),
    MatriculePaie: () => import("@/Components/Commons/Interface/Notifications/MatriculePaie"),
    Rapport: () => import("@/Components/Commons/Interface/Notifications/Rapport"),
    ListContrats: () => import("@/Components/Commons/Interface/Notifications/ListContrats"),
    ListCandidats: () => import("@/Components/Commons/Interface/Notifications/ListCandidats")
  },
  computed: {
    ...Vuex.mapState(["notifications"]),
    notificationCategories() {
      return this.notifications
    },
    hasNewNotifications() {
      return this.notifications.reduce((acc, category) => acc + category.items.filter(notif => !notif.seen).length, 0)
    },

  },
  data() {
    return {
      intervals: {
        poseSdp: null,
        interim: null,
        commandes: null,
        matriculePaie: null,
        rapport: null,
        listContrats: null,
      },
      syncDelay: 600000 // 10 min
    }
  },
  methods: {
    calcHeight(cat) {
      return Math.min(cat.items.length*64, 208)
    },
    getPoseSdp() {
      NotificationsService
        .getPoseSdp()
        .then(responsePoseSdp => {
          if (responsePoseSdp && responsePoseSdp.count > 0) {

            let category = {
              slug: "poseSdp",
              icon: "site-du-personnel",
              title: this.$t("notifications.poseSdp.title"),
              component: "PoseSdp",
              height: 64
            }

            this.$store.commit("addNotification", {category, reset: true})

            for (let alert of responsePoseSdp.list) {

              let alertToAdd = {
                seen: false,
                title: this.$t("notifications.poseSdp.subtitle"),
                data: alert
              }

              this.$store.commit("addNotification", {category, data: alertToAdd, reset: false})
            }
          }
          if (responsePoseSdp != 404) {
            setTimeout(this.getPoseSdp, this.syncDelay)
          }
        })
    },
    getInterim() {
      NotificationsService
        .getInterim()
        .then(responseInterim => {
            if (responseInterim && responseInterim.count > 0) {

              let category = {
                slug: "interim",
                icon: "interimaire",
                title: this.$t("notifications.interim.title"),
                component: "Interim",
                height: 64
              }

              this.$store.commit("addNotification", {category, reset: true})

              for (let alert of responseInterim.list) {

                let alertToAdd = {
                  seen: false,
                  title: this.$t("notifications.interim.subtitle"),
                  data: alert
                }

                this.$store.commit("addNotification", {category, data: alertToAdd, reset: false})
              }
            }
            if (responseInterim != 404) {
              setTimeout(this.getInterim, this.syncDelay)
            }
          })
    },
    getCommandes() {
      NotificationsService
        .getCommandes()
        .then(responseCommandes => {
          if (responseCommandes && responseCommandes.count > 0) {

            let category = {
              slug: "commandes",
              icon: "materiel",
              title: this.$t("notifications.commandes.title"),
              component: "Commandes",
              height: 64
            }

            this.$store.commit("addNotification", {category, reset: true})

            for (let alert of responseCommandes.list) {

              let alertToAdd = {
                seen: false,
                title: this.$t("notifications.commandes.subtitle"),
                data: alert
              }
              this.$store.commit("addNotification", {category, data: alertToAdd, reset: false})
            }
          }
          if (responseCommandes != 404) {
            setTimeout(this.getCommandes, this.syncDelay)
          }
        })
    },
    getMatriculePaie() {
      NotificationsService
        .getMatriculePaie()
        .then(responseMatriculePaie => {
          if (responseMatriculePaie && responseMatriculePaie.count > 0) {

            let category = {
              slug: "matriculePaie",
              icon: "compteur-paie",
              title: this.$t("notifications.matriculePaie.title"),
              component: "MatriculePaie",
              height: 64
            }

            this.$store.commit("addNotification", {category, reset: true})

            for (let alert of responseMatriculePaie.list) {

              let alertToAdd = {
                seen: false,
                title: this.$t("notifications.matriculePaie.subtitle"),
                data: alert
              }

              this.$store.commit("addNotification", {category, data: alertToAdd, reset: false})
            }
          }
          if (responseMatriculePaie != 404) {
            setTimeout(this.getMatriculePaie, this.syncDelay)
          }
        })
    },
    getRapport() {
      NotificationsService
        .getRapport()
        .then(responseRapport => {
          if (responseRapport && responseRapport.count >= 0) {

            let category = {
              slug: "rapport",
              icon: "saisie-prestation",
              title: this.$t("notifications.rapport.title"),
              component: "Rapport",
              height: 96
            }

            this.$store.commit("addNotification", {category, reset: true})

            for (let alert of responseRapport.list) {

              let alertToAdd = {
                seen: false,
                title: this.$t("notifications.rapport.subtitle"),
                data: alert
              }

              this.$store.commit("addNotification", {category, data: alertToAdd, reset: false})
            }
          }
          if (responseRapport != 404) {
            setTimeout(this.getRapport, this.syncDelay)
          }
        })
    },
    getListContrats() {
      NotificationsService
        .getListContrats()
        .then(responseListContrats => {
          if (responseListContrats && responseListContrats.count >= 0) {

            let category = {
              slug: "listContrats",
              icon: "saisie-prestation",
              title: this.$t("notifications.listContrats.title"),
              component: "list-contrats",
              height: 64
            }

            this.$store.commit("addNotification", {category, reset: true})

            for (let alert of responseListContrats.list) {

              let alertToAdd = {
                seen: false,
                title: this.$t("notifications.listContrats.subtitle"),
                data: alert
              }

              this.$store.commit("addNotification", {category, data: alertToAdd, reset: false})
            }
          }
          if (responseListContrats != 404) {
            setTimeout(this.getListContrats, this.syncDelay)
          }
        })
    },
    getListCandidats() {
      NotificationsService
          .getListCandidats()
          .then(responseListCandidats => {
            if (responseListCandidats && responseListCandidats.dataList && responseListCandidats.dataList.length >= 0) {

              let category = {
                slug: "listCandidats",
                icon: "interimaire",
                title: this.$t("notifications.listCandidats.subtitle"),
                component: "list-candidats",
                height: 64
              }

              this.$store.commit("addNotification", {category, reset: true})

              for (let alert of responseListCandidats.dataList) {

                let alertToAdd = {
                  notificationDisplay: responseListCandidats.notificationDisplay,
                  detailsDisplay: responseListCandidats.detailsDisplay,
                  data: alert
                }

                this.$store.commit("addNotification", {category, data: alertToAdd, reset: false})
              }
            }
            if (responseListCandidats != 404) {
              setTimeout(this.getListCandidats, this.syncDelay)
            }
          })
    }
  },
  async mounted() {
    const alertRights = await NotificationsService.getRights()
    await this.$store.commit("setAlertRights", alertRights)

    if (this.$store.getters.getAlertRights("poseSdp")) {
      this.getPoseSdp()
    }
    if (this.$store.getters.getAlertRights("interim")) {
      this.getInterim()
    }
    if (this.$store.getters.getAlertRights("commandes")) {
      this.getCommandes()
    }
    if (this.$store.getters.getAlertRights("matriculePaie")) {
      this.getMatriculePaie()
    }
    if (this.$store.getters.getAlertRights("rapport")) {
      this.getRapport()
    }
    if (this.$store.getters.getAlertRights("listContrats")) {
      this.getListContrats()
    }
    if (this.$store.getters.getAlertRights("listCandidats")) {
      this.getListCandidats()
    }
  }
}
</script>

<style scoped>
.max-height {
  max-height: 280px;
}
</style>